import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SendEmailService } from 'src/app/services/send-email/send-email.service';

@Component({
  selector: 'app-sidebar-basket',
  templateUrl: './sidebar-basket.component.html',
  styleUrls: ['./sidebar-basket.component.css'],
})
export class SidebarBasketComponent implements OnInit {
  constructor(
    private mailService: SendEmailService,
    public dialog: MatDialog
  ) {}

  basketData = [];
  totalAmnt = 0;
  sendingEmail = false;
  phoneNumber;
  bandi;

  @ViewChild('dialogTemplate') dtemplate: TemplateRef<any>;
  @ViewChild('dialogErrorTemplate') dErrortemplate: TemplateRef<any>;
  @ViewChild('dialogSuccessTemplate') dSuccessTemplate: TemplateRef<any>;

  ngOnInit(): void {}

  public addItemToBasket(item): void {
    const duplicateItem = this.basketData.find(
      (elm) => elm['Item No_'] === item['Item No_']
    );
    if (!duplicateItem) {
      let tmpItem = item;
      tmpItem['qtyToBuy'] = 1;
      this.basketData.push(tmpItem);
    }
    this.calculateTotal();
  }

  removeItemFromBasket(item) {
    this.basketData.forEach((elm, index) => {
      if (elm['Item No_'] === item['Item No_']) {
        this.basketData.splice(index, 1);
      }
    });
    this.calculateTotal();
  }

  calculateTotal() {
    let tempTotal = 0;
    this.basketData.forEach((elm) => {
      tempTotal += elm['Unit Price'] * elm['qtyToBuy'];
    });
    this.totalAmnt = tempTotal;
  }

  totalOfEach(item) {
    return item['Unit Price'] * item['qtyToBuy'];
  }

  handleQuantityChange(val, item) {
    item['qtyToBuy'] = val;
    this.calculateTotal();
  }

  sendOrder() {
    // let body = '';
    // this.basketData.forEach((elm) => {
    //   body += elm['Item No_'] + ' оос ' + elm['qtyToBuy'] + '-н ширхэгийг<br>';
    // });

    // body += 'Баярлалаа';

    const dialogRef = this.dialog.open(this.dtemplate);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'ok') {
        this.sendMail({
          data: this.basketData,
          phoneNumber: this.phoneNumber,
        });
      }
    });
  }

  showError() {
    this.dialog.open(this.dErrortemplate);
  }

  showSuccess() {
    this.dialog.open(this.dSuccessTemplate);
  }

  removeAllItemFromBasket() {
    this.basketData = [];
    this.calculateTotal();
  }

  sendMail(data) {
    this.sendingEmail = true;
    this.mailService.sendEmail('Parts Order', JSON.stringify(data)).subscribe(
      (res) => {
        console.log(res);
        this.sendingEmail = false;
        this.showSuccess();
      },
      (err) => {
        console.error(err);
        this.sendingEmail = false;
        this.showError();
      }
    );
  }

  handleNumberchange(pnum) {
    this.phoneNumber = pnum;
  }
}
