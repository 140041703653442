import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(protected http: HttpClient) {}

  private url = '/api';

  sendGet(endpoint?): Observable<any> {
    return this.http.get(this.url + '/search-tool');
  }

  sendPost(endpoint, body): Observable<any> {
    return this.http.post(
      this.url + '/send-email',
      body,
      {responseType: 'text'}
    );
  }

  getImages(id) {
    return this.http.get(this.url + '/images/' + id + '.jpg');
  }

  getSuggest(query){
    return this.http.get(this.url + '/search-tool/' + query);
  }
}
