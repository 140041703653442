import { CommonModule, CurrencyPipe } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ModalComponent } from './custom-components/modal/modal.component';
import { PdfViewerComponent } from './custom-components/pdf-viewer/pdf-viewer.component';
import { PhoneNumberComponent } from './custom-components/phone-number/phone-number.component';
import { QuantityComponent } from './custom-components/quantity/quantity.component';
import { ScreenLoaderComponent } from './custom-components/screen-loader/screen-loader.component';
import { SearchDetailComponent } from './search-tool/search-detail/search-detail.component';
import { SearchToolComponent } from './search-tool/search-tool.component';
import { SidebarBasketComponent } from './search-tool/sidebar-basket/sidebar-basket.component';


@NgModule({
  declarations: [
    AppComponent,
    SearchToolComponent,
    SearchDetailComponent,
    SidebarBasketComponent,
    QuantityComponent,
    ModalComponent,
    PhoneNumberComponent,
    ScreenLoaderComponent,
    PdfViewerComponent,
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatSidenavModule,
    MatDialogModule,
    PdfViewerModule,
  ],
  entryComponents: [PdfViewerComponent],
  providers: [CurrencyPipe],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
