import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { debounceTime, filter, map, tap } from 'rxjs/operators';
import { PdfViewerComponent } from '../custom-components/pdf-viewer/pdf-viewer.component';
import { SearchToolService } from '../services/search-tool/search-tool.service';
import { SidebarBasketComponent } from './sidebar-basket/sidebar-basket.component';

const IDLE_TIME = 180; // 3 minut idle bol ehnii tuluvruu shiljine

@Component({
  selector: 'app-search-tool',
  templateUrl: './search-tool.component.html',
  styleUrls: ['./search-tool.component.css'],
  animations: [
    trigger('openClose', [
      // ...
      state(
        'searching',
        style({
          flexDirection: 'row',
          display: 'flex',
          width: '99%',
          height: '15vh',
          alignItems: 'center',
        })
      ),
      state(
        'idle',
        style({
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          display: 'flex',
          height: '100vh',
          width: '100%',
        })
      ),
      transition('searching => idle', [animate('0s ease-out')]),
      transition('idle => searching', [animate('0s ease-out')]),
    ]),
    trigger('openCloseImg', [
      state(
        'searchingImg',
        style({
          margin: '20px',
          width: '20%',
        })
        // style="width: 80%; margin: 20px"
      ),
      state(
        'idleImg',
        style({
          margin: '20px',
          width: '80%',
        })
      ),
      transition('searchingImg => idleImg', [animate('1s ease-out')]),
      transition('idleImg => searchingImg', [animate('1s ease-out')]),
    ]),
  ],
})
export class SearchToolComponent implements OnInit {
  constructor(private service: SearchToolService, private dialog: MatDialog) {}
  model4: any;
  searchFailed = false;
  result: any;
  data = [];
  isSearching = false;
  filteredStreets: Observable<string[]>;
  suggestPartsData;
  loading = false;
  retrievingDataFromServer = false;
  timer = IDLE_TIME;
  interval;

  @ViewChild('sBasket') sideBasket: SidebarBasketComponent;

  input = new FormControl();

  ngOnInit(): void {
    this.input.valueChanges
      .pipe(
        filter((a) => !!a),
        tap((_) => (this.loading = true)),
        debounceTime(300),
        map((val: string) => val.toUpperCase())
      )
      .subscribe((val) => {
        console.log(val);
        this.suggestParts(val);
      });

    // this.service.getImages().subscribe( data => {
    //   console.log(data);
    // })
  }

  suggestParts(val) {
    this.service.getSuggest(val).subscribe(
      (res) => {
        this.suggestPartsData = res;
        this.loading = false;
      },
      (err) => console.log(err)
    );
    // this.suggestPartsData = this.data.filter(
    //   (elm) =>
    //     elm['Item No_'].includes(val) ||
    //     (elm['Description'] && elm['Description'].toUpperCase().includes(val))
    // );
    // this.loading = false;
  }

  // suggestParts(val) {
  //   this.suggestPartsData = this.data.filter(
  //     (elm) =>
  //       elm['Item No_'].includes(val) ||
  //       (elm['Description'] && elm['Description'].toUpperCase().includes(val))
  //   );
  //   this.loading = false;
  // }

  searchParts() {
    const part = this.suggestPartsData.find(
      (elm) => elm['Item No_'] === this.input.value.toUpperCase()
    );
    if (part) {
      this.isSearching = true;
      this.result = [part];
      console.log(this.result);
    }
  }

  toggle() {
    this.isSearching = !this.isSearching;
    if (this.isSearching) {
      this.retreiveDataFromServer();
      this.generateInterval();
    }
  }

  @HostListener('keyup', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    // if (event.keyCode === 13 && this.input.value) {
    //   this.searchParts();
    // }
    console.log('keyboard: ' + event.key);
    this.refreshTime();
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    console.log('mouse: ' + event);
    this.refreshTime();
  }

  addToBasket() {
    console.log(this.result);
    this.sideBasket.addItemToBasket(this.result[0]);
  }

  showWarranty() {
    const dialogRef = this.dialog.open(PdfViewerComponent);
    const lang = localStorage.getItem('locale');
    dialogRef.componentInstance.pdfSrc = `/assets/pdfs/war-${lang}.pdf`;
  }

  showReturnPolicy() {
    const dialogRef = this.dialog.open(PdfViewerComponent);
    dialogRef.componentInstance.pdfSrc = '/assets/pdfs/ret.pdf';
  }

  roundUp(num) {
    return Math.ceil(num);
  }

  retreiveDataFromServer() {
    this.retrievingDataFromServer = true;
    this.service.getDetailOfTool().subscribe(
      (data) => {
        console.log(data);
        this.retrievingDataFromServer = false;
      },
      (err) => {
        console.error(err);
        this.retrievingDataFromServer = false;
      }
    );
  }

  generateInterval() {
    this.interval = setInterval(() => {
      this.timer--;
      if (this.timer === 0) {
        this.toggle();
        this.result = undefined;
        this.clearInterval();
      }
      console.log(this.timer);
    }, 1000);
  }

  refreshTime() {
    this.timer = IDLE_TIME;
  }

  clearInterval() {
    clearInterval(this.interval);
  }
}
