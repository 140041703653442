import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'kiosk';
  lang = 'mn';
  constructor(private translate: TranslateService) {
    let ln = localStorage.getItem('locale');
    if(ln){
      this.lang =  ln;
    }else{
      localStorage.setItem('locale',this.lang);
    }
    translate.setDefaultLang(this.lang);
  }

  changeLang(code) {
    this.lang = code;
    localStorage.setItem('locale',code);
    this.translate.use(code);
  }
}
