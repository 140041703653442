import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable, of } from 'rxjs';

const mockData = [
]

@Injectable({
  providedIn: 'root',
})
export class SearchToolService {
  constructor(private api: ApiService) {
    // for( let i =0; i< 200; i++){
    //   mockData.push({ id: i.toString(), name: `Parts ${i}`, price: `${Math.round(Math.random()*1000)} $`, stock: `${Math.round(Math.random()*10)} pieces left`});
    // }
  }

  getDetailOfTool(queryParam?){
    // return of(mockData);
    return this.api.sendGet(queryParam);
  }

  getImages(id){
    return this.api.getImages(id);
  }

  getSuggest(query){
    return this.api.getSuggest(query);
  }
}
