<div style="display: flex; flex-direction: row">
  <ng-container *ngFor="let indx of arrayOne(8); let i = index;">
    <input
      #inp
      type="number"
      class="pBox"
      min="0"
      max="9"
      (keydown)="handleChange(i, $event, inp)"
    />
  </ng-container>
</div>
