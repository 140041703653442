import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.css'],
})
export class PhoneNumberComponent implements OnInit {
  constructor(private elementRef: ElementRef) {}

  phoneNumber = [];

  @Output() numberChange = new EventEmitter<any>();

  ngOnInit(): void {}

  arrayOne(n: number): any[] {
    return Array(n);
  }

  handleChange(index, event: KeyboardEvent, elm) {
    event.preventDefault();
    event.stopPropagation();
    if (Number.isInteger(Number(event.key))) {
      elm.value = Number(event.key);
      if (index !== 7) {
        elm.nextElementSibling.focus();
      }
    } else {
      if (event.key === 'Backspace') {
        elm.value = null;
        if (index !== 0) {
          elm.previousElementSibling.focus();
        }
      }
    }

    this.phoneNumber[index] = elm.value;
    console.log(this.phoneNumber.join(''));
    this.numberChange.emit(this.phoneNumber.join(''));
  }
}
