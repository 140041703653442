<div class="wrapper" style="display: flex; flex-direction: column; width: 50px; height: 100%;">
  <button class="btn-plus" (click)="increment()">+</button>
  <div class="input-wrapper">
    <input
      #inp
      type="number"
      class="input-style"
      [value]="value"
      (change)="handleValueChange(inp.value)"
    />
  </div>
  <button class="btn-minus" (click)="decrement()">-</button>
</div>
