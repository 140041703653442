<div style="display: flex; flex-direction: row">
  <div [style.width]="isSearching ? '75%' : '100%'">
    <div [@openClose]="isSearching ? 'searching' : 'idle'">
      <img
        [@openCloseImg]="isSearching ? 'searchingImg' : 'idleImg'"
        src="assets/images/barlo-head.png"
        alt=""
        style="transition: all 1s ease-in-out"
      />
      <div style="flex-grow: 1; width: 80%; display: flex; align-items: center; justify-content: center;">
        <div
          *ngIf="isSearching"
          class="input-group input-group-lg"
          style="padding: 50px 0"
        >
          <form style="flex-grow: 1; display: flex; align-items: center">
            <div
              [style.color]="loading ? 'black' : 'white'"
              class="spinner-grow m-2"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
            <input
              type="text"
              class="form-control"
              style="height: 100%; font-size: 1.5rem;"
              placeholder="{{ 'fillIdHere' | translate }}"
              aria-label="Large"
              aria-describedby="inputGroup-sizing-sm"
              [formControl]="input"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                *ngFor="let part of suggestPartsData"
                [value]="part['Item No_']"
                (click)="searchParts()"
              >
                <strong>{{ part["Item No_"] }}</strong> -
                {{ part["Description"] }}
              </mat-option>
            </mat-autocomplete>
          </form>
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              type="button"
              style="font-size: 2rem;"
              (click)="searchParts()"
            >
              {{ "search" | translate }}
            </button>
            <!-- <button
              class="btn btn-outline-secondary"
              type="button"
              (click)="toggle()"
            >
              Toggle
            </button> -->
          </div>
        </div>
        <div
          *ngIf="!isSearching"
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            justify-content: center;
            padding-bottom: 10%;
          "
        >
          <button
            style="
              font-size: 40px;
              border: none;
              background-color: #fec409;
              padding: 10px;
              font-weight: bold;
              text-transform: uppercase;
            "
            (click)="toggle()"
          >
            {{ "findParts" | translate }}
          </button>
        </div>
      </div>
    </div>

    <div
      *ngIf="isSearching"
      style="
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-grow: 1;
        height: 85vh;
      "
    >
      <div
        *ngIf="result && result.length > 0; else empty"
        style="
          display: flex;
          align-items: center;
          flex-direction: column;
          flex-grow: 1;
        "
      >
        <div style="display: flex; width: 100%; padding: 0px 20px 50px 20px">
          <strong
            style="
              font-size: 5em;
              line-height: 1;
              flex-grow: 1;
              text-align: center;
            "
            >{{ result[0] ? result[0]["Item No_"] : "" }} :
            {{ result[0]?.Description }}</strong
          >
          <div
            style="display: flex; align-items: center; justify-self: flex-end"
          >
            <button
              [disabled]="result[0]['Availibility Quantity'] === 0 || result[0]['Availibility Quantity'] === null"
              type="button"
              class="btn btn-add-to-cart"
              (click)="addToBasket()"
            >
              <img src="assets/icons/plus-solid.svg" style="width: 20px" />
              {{ "addToCart" | translate }}
            </button>
          </div>
        </div>
        <div style="display: flex; flex-grow: 1; margin-bottom: 15%">
          <img
            style="
              width: 250px;
              border: 1px dashed #b7b7b7;
              margin-right: 5rem;
              align-self: center;
              border: 1px solid rgb(197, 199, 208);
              box-shadow: rgb(197, 199, 208) 0px 4px 15px 0px;
            "
            src="{{ 'images/' + result[0]['Item No_'] + '.jpg' }}"
            onerror="this.src='/assets/images/default.png';"
          />
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding: 1rem;
            "
          >
            <span
              >{{ "price" | translate }}:
              <strong class="custom-strong">{{
                (result[0] ? roundUp(result[0]["Unit Price"]) : "")
                  | currency: "MNT":"₮"
              }}</strong></span
            >
            <span
              >{{ "returnable" | translate }}:
              <strong class="custom-strong">{{
                (result[0]["Is Returnable"] === 'Yes' ? 'yes' : 'no') | translate
              }}</strong></span
            >
            <span
              >{{ "availQuantity" | translate }}:
              <strong class="custom-strong">{{
                result[0] ? roundUp(result[0]["Availibility Quantity"]) : ""
              }}</strong></span
            >
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                margin-top: 5%;
              "
            >
              <button
                class="btn btn-outline-secondary"
                type="button"
                (click)="showWarranty()"
              >
                {{ "warranty" | translate }}
              </button>
              <button
                class="btn btn-outline-secondary"
                type="button"
                (click)="showReturnPolicy()"
                style="margin-left: 10px"
              >
                {{ "returnPolicy" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-sidebar-basket #sBasket *ngIf="isSearching"></app-sidebar-basket>
</div>

<ng-template #empty>
  <div style="display: flex; justify-content: center; flex-grow: 1">
    <span style="font-size: 5em; margin-top: 20%">
      {{ (result === undefined ? "enterId" : "noData") | translate }}</span
    >
  </div></ng-template
>
<div style="display: flex; justify-content: center; align-items: center">
  <p style="position: absolute; bottom: 0">Made with 🖤 by Barloworld Mongolia</p>
</div>

<app-screen-loader
  *ngIf="retrievingDataFromServer"
  message="{{ 'updateData' | translate }}"
></app-screen-loader>
