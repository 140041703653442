<div
  style="
    border: 1px solid rgb(246, 246, 246);
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: rgb(246, 246, 246);
  "
>
  <ng-container *ngIf="basketData.length === 0"
    ><div
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
      "
    >
      <img style="width: 100px" src="assets/images/cart.svg" alt="" /><span
        style="color: #b7b7b7; font-size: 2rem"
        >{{ "cartEmpty" | translate }}</span
      >
    </div></ng-container
  >

  <ng-container *ngIf="basketData.length > 0">
    <div style="display: flex; flex-direction: row; background-color: white">
      <div style="display: flex; align-items: center">
        <span
          style="flex-grow: 1; margin: 0; padding-left: 10px; font-size: 25px"
          >{{ "totalItems" | translate }}:
          <strong>{{ basketData.length }}</strong></span
        >
      </div>
      <button
        type="button"
        style="margin-left: auto"
        class="btn btn-danger"
        (click)="removeAllItemFromBasket()"
      >
        {{ "removeAll" | translate }}
      </button>
    </div>
  </ng-container>
  <div *ngIf="basketData.length > 0" style="flex-grow: 1; overflow: auto">
    <ng-container *ngFor="let item of basketData">
      <div style="display: flex; max-height: 8rem;" class="basket-item">
        <img
          style="width: 5rem; margin: auto"
          src="{{ 'images/' + item['Item No_'] + '.jpg' }}"
          onerror="this.src='/assets/images/default.png';"
        />
        <app-quantity
          (valueChange)="handleQuantityChange($event, item)"
          [maxValue]="item['Availibility Quantity']"
        ></app-quantity>
        <div
          style="
            display: flex;
            flex-direction: column;
            margin-right: 20px;
            flex-grow: 1;
          "
        >
          <strong>
            <span>
              {{ item ? item["Item No_"] : "" }} : {{ item?.Description }}</span
            >
          </strong>
          <div style="display: flex; flex-direction: row">
            <span> {{ "unitPrice" | translate }}: </span>
            <span style="margin-left: auto; font-family: sans-serif">
              {{ (item ? item["Unit Price"] : "") | currency: "MNT":"₮" }}
            </span>
          </div>
          <div style="display: flex; flex-direction: row">
            <span>{{ "totalPrice" | translate }}: </span>
            <span style="margin-left: auto">
              <strong style="font-family: sans-serif">{{
                totalOfEach(item) | currency: "MNT":"₮"
              }}</strong>
            </span>
          </div>
        </div>
        <button
          type="button"
          style="margin-left: auto"
          class="btn btn-danger"
          (click)="removeItemFromBasket(item)"
        >
          <img src="assets/icons/bin-white.svg" alt="" style="width: 20px" />
        </button>
      </div>
    </ng-container>
  </div>
  <button
    class="btn btn-success"
    [disabled]="basketData.length === 0"
    (click)="sendOrder()"
    style="
      background-color: rgb(254 196 9);
      color: black;
      border-color: rgb(254 196 9);
    "
  >
    <div style="display: flex; flex-direction: column">
      <span
        style="font-size: 2rem; font-weight: bold; text-transform: uppercase"
        >{{ "placeAnOrder" | translate }}</span
      >
      <span style="font-size: 2rem; font-weight: bold"
        >{{ totalAmnt | currency: "MNT":"₮" }}
      </span>
    </div>
  </button>
</div>

<ng-template #dialogTemplate>
  <h1 mat-dialog-title align="center">{{ "fillPhoneNumber" | translate }}</h1>
  <app-phone-number
    (numberChange)="handleNumberchange($event)"
  ></app-phone-number>
  <mat-dialog-actions align="center">
    <button
      mat-button
      [mat-dialog-close]="'ok'"
      class="btn btn-success"
      style="
        background-color: rgb(254 196 9);
        color: black;
        border-color: rgb(254 196 9);
        margin-top: 10px;
        margin-bottom: 20px;
        font-weight: bold;
        text-transform: uppercase;
      "
    >
      {{ "placeAnOrder" | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #dialogErrorTemplate>
  <h1 mat-dialog-title align="center">{{ "orderError" | translate }}</h1>
  <mat-dialog-actions align="center">
    <button
      mat-button
      [mat-dialog-close]="'ok'"
      class="btn btn-success"
      style="
        background-color: rgb(254 196 9);
        color: black;
        border-color: rgb(254 196 9);
        margin-top: 10px;
        margin-bottom: 20px;
        font-weight: bold;
      "
    >
      {{ "close" | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #dialogSuccessTemplate>
  <h1 mat-dialog-title align="center">{{ "orderSuccess" | translate }}</h1>
  <mat-dialog-actions align="center">
    <button
      mat-button
      [mat-dialog-close]="'ok'"
      class="btn btn-success"
      style="
        background-color: rgb(254 196 9);
        color: black;
        border-color: rgb(254 196 9);
        margin-top: 10px;
        margin-bottom: 20px;
        font-weight: bold;
      "
    >
      {{ "close" | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>

<app-screen-loader
  *ngIf="sendingEmail"
  message=" {{ 'placingOrder' | translate }}"
></app-screen-loader>
