import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class SendEmailService {
  constructor(private api: ApiService) {}

  sendEmail(subject, message) {
    return this.api.sendPost('/send-email', {
      subject: subject,
      message: message,
    });
  }
}
