import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-quantity',
  templateUrl: './quantity.component.html',
  styleUrls: ['./quantity.component.css'],
})
export class QuantityComponent implements OnInit {
  constructor() {}

  @Input() value = 1;
  @Input() maxValue :number;
  @Output() valueChange = new EventEmitter<number>();

  ngOnInit(): void {}

  handleValueChange(val?) {
    if (val) {
      if (val > this.maxValue) {
        this.value = this.maxValue;
      } else {
        this.value = val;
      }
    }
    this.valueChange.emit(this.value);
  }

  increment() {
    if ((this.value + 1) <= this.maxValue) {
      this.value += 1;
      this.handleValueChange();
    }
  }

  decrement() {
    if ((this.value - 1) > 0) {
      this.value -= 1;
      this.handleValueChange();
    }
  }
}
